export default {
  methods: {
     sum(arr) {
       return arr.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
     },
     count(arr) {
      const counts = {}
      for (const i of arr) {
        counts[i] = counts[i] ? counts[i] + 1 : 1;
      }
      return counts
     }
  }
}