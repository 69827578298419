import subDays from 'date-fns/subDays'
import startOfMonth from 'date-fns/startOfMonth'
import subMonths from 'date-fns/subMonths'
import endOfMonth from 'date-fns/endOfMonth'
import startOfQuarter from 'date-fns/startOfQuarter'
import subQuarters from 'date-fns/subQuarters'
import endOfQuarter from 'date-fns/endOfQuarter'
import startOfYear from 'date-fns/startOfYear'
import subYears from 'date-fns/subYears'
import endOfYear from 'date-fns/endOfYear'
import eachHourOfInterval from 'date-fns/eachHourOfInterval'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval'
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval'
import endOfToday from 'date-fns/endOfToday'
import startOfToday from 'date-fns/startOfToday'
import endOfYesterday from 'date-fns/endOfYesterday'
import startOfYesterday from 'date-fns/startOfYesterday'
// eslint-disable-next-line no-unused-vars
import parseISO from 'date-fns/parseISO'

const today = new Date(Date.now())

export default {
  data: () => ({
    periods: ["Custom","Today","Yesterday","Last 7 Days","Last Month", "Last 30 Days", "Last Quarter", "Last 90 Days", "Last Year", "Last 12 Months", "Last 365 Days"],
  }),
  methods: {
    getPeriod(period) {
      switch (period){
        case "Today":
          return {
            start: startOfToday(),
            end: endOfToday()
          }
        case "Yesterday":
          return {
            start: startOfYesterday(),
            end: endOfYesterday()
          }
        case "Last 7 Days":
          return {
            start: subDays(today, 7),
            end: today
          }
        case "Last Month":
          return {
            start: startOfMonth(subMonths(today, 1)),
            end: endOfMonth(subMonths(today, 1))
          }
        case "Last 30 Days":
          return {
            start: subDays(today, 30),
            end: today
          }
        case "Last Quarter": 
          return {
            start: startOfQuarter(subQuarters(today, 1)),
              end: endOfQuarter(subQuarters(today, 1))
          }
        case "Last 90 Days":
          return {
            start: subDays(today, 90),
            end: today
          }
        case "Last Year": 
          return {
            start: startOfYear(subYears(today, 1)),
              end: endOfYear(subYears(today, 1))
          }
        case "Last 12 Months":
          return {
            start: startOfMonth(subMonths(today, 12)),
            end: startOfMonth(today)
          }
        case "Last 365 Days":
          return {
            start: subDays(today, 365),
            end: today
          }
      }
    },
    getIntervalDates(period){
      switch (period){
        case "Today":
          return eachHourOfInterval(this.getPeriod("Today"))
        case "Yesterday":
          return eachHourOfInterval(this.getPeriod("Yesterday"))
        case "Last 7 Days":
          return eachDayOfInterval(this.getPeriod("Last 7 Days"))
        case "Last Month":
          return eachDayOfInterval({
            start: startOfMonth(subMonths(today, 1)),
            end: endOfMonth(subMonths(today, 1))
          })
        case "Last 30 Days":
          return eachDayOfInterval(this.getPeriod("Last 30 Days"))
        case "Last Quarter": 
        return eachWeekOfInterval({
          start: startOfQuarter(subQuarters(today, 1)),
            end: endOfQuarter(subQuarters(today, 1))
        })
        case "Last 90 Days":
          return eachWeekOfInterval(this.getPeriod("Last 90 Days"))
        case "Last Year": 
        return eachMonthOfInterval({
          start: startOfYear(subYears(today, 1)),
            end: endOfYear(subYears(today, 1))
        })
        case "Last 12 Months":
          return eachMonthOfInterval({
            start: startOfMonth(subMonths(today, 12)),
            end: startOfMonth(today)
        })
        case "Last 365 Days":
          return eachWeekOfInterval(this.getPeriod("Last 365 Days"))
        default:
          return eachDayOfInterval(period)
      } 
    }
  }
}