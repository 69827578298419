`<template>
  <v-container>
    <v-row class="text-h5">
      <v-col cols="10"> Inventory Use </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Raw Materials
          </v-card-title>
          <v-card-text>
            <div v-for='total in rawMaterialsTotals' :key="total.name">
              {{total.name}}: {{total.qty}}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Doors
          </v-card-title>
          <v-card-text>
            <div v-for='door in shippedDoors' :key="door.name">
              {{door.color}}: {{door.count}}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Boxes
          </v-card-title>
          <v-card-text>
            <div v-for='(value, key) in shippedBoxes' :key="key">
              {{key}}: {{value}}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import strapiApi from "../mixins/strapiApi"
import stats from "../mixins/stats"
import period from "../mixins/period"

import qs from "qs"

export default {
  name: "InventoryUse",
  mixins: [strapiApi, stats, period],
  props: {period: Object},
  data: () => ({
    result: null,
    products: [],
  }),
  watch: {
    period(){
      this.sendQuery()
    }
  },
  async mounted() {
    try {
      this.products = await this.getProducts("")
      this.products = await Promise.all(
      this.products.map(async (product) => {
        product.parts = this.getParts(product)
        return product
      }))
      await this.sendQuery()
    } catch (error) {
      console.error(error)
    }
  },
  computed: {
    mappedResult() {
      return this.result.flatMap(r => {
        return r.lineItems
      })
    },
    rawMaterialsTotals() {
      let rawMaterials = this.mappedResult.map(mr => {
        return this.getRawMaterials(this.getStrapiProduct(mr.product_id)).map(rm => {
          
          const tempRm = {
            name: rm.name,
            qty: rm.qty * mr.quantity,
            cost: rm.cost
          }
          return tempRm
        })
      }).flat()
      const matNames = Array.from(
        new Set(rawMaterials.flat().flatMap((rm) => rm.name))
      )
      return matNames.map(name => {
        return {
          name: name,
          qty: this.sum(rawMaterials.filter(rm => rm.name == name).map(rm => rm.qty))
        }
      })
    },
    shippedDoors(){
      const owedDoors = this.mappedResult.filter(mr => mr.name.includes("Storage Cube"))
      .flatMap((ol) => {
        let color
        if (ol.variant_title.indexOf("/") > 0)
        {
          color = ol.variant_title.slice(0, ol.variant_title.indexOf("/") - 1)
        }
        else color = ol.variant_title
        return Array(ol.fulfillable_quantity).fill(color)
      })
      const doorTypes = Array.from(new Set(owedDoors))
      return doorTypes.map((dt) => {
        return {
          color: dt,
          count: owedDoors.filter((od) => od == dt).length,
        }
      })
    },
    shippedBoxes(){
      const boxes = this.mappedResult.flatMap(mr => {
        return Array(mr.quantity).fill(this.getStrapiProduct(mr.product_id).package.name)
      })
      return this.count(boxes)
    }
  },
  methods: {
    async sendQuery() {
      const period = this.period //this.getPeriod(this.period)
      const query = qs.stringify({
        _where: [
          { created_at_lte: period.end },
          { created_at_gte: period.start }
        ]
      })
      this.result = await this.strapiGet(`shipments?_limit=-1&${query}`)
    },
    getStrapiProduct(id){
      return this.products.find(p => p.shopifyId == id)
    },
    async getPart(id) {
      if (id == null) return
      return this.strapiGet(`parts/${id}`)
    },
    async getParts(product) {
      product.parts = await Promise.all(
        product.parts.map(async (partCount) => {
          partCount.part = await this.getPart(partCount.part.id)
          return partCount
        })
      )
      return product
    },
    getRawMaterials(product) {
      return product.parts.flatMap((part) => {
        let arr = new Array(part.quantity)
        if (!part.part.rawMaterial || part.part.rawMaterial.length < 1) {
          return {
            name: part.part.Name,
            qty: part.quantity,
            cost: part.part.materialCost
          }
        }
        arr.fill(
          part.part.rawMaterial.flatMap((rm) => {
            return {
              name: rm.raw_material.Name,
              qty: rm.Quantity,
              cost: rm.raw_material.cost
            }
          })
        )
        return arr.flat()
      })
    },
  }
}
</script>
<style>
</style>
