`<template>
  <v-container>
    <v-row class="text-h5">
      <v-col cols="10"> Sold Vs. Shipped </v-col>
    </v-row>
    <v-row v-if='owed.length > 1'>
      <v-col>
      <line-chart :data='chartData' :config='chartOptions'/>
      </v-col>
    </v-row>
    <v-row v-if="shipped">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Sold
          </v-card-title>
          <v-card-text>
            ${{soldValue}}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Shipped
          </v-card-title>
          <v-card-text>
            ${{shippedValue}}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Owed
          </v-card-title>
          <v-card-text>
            ${{owed[owed.length - 1].data}} owed at end of period
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import strapiApi from "../mixins/strapiApi";
import stats from "../mixins/stats"
import period from "../mixins/period"

import qs from "qs";
import LineChart from '../components/LineChart.vue';
// import { Line as LineChart } from 'vue-chartjs'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { LineChart },
  name: "SoldShipped",
  mixins: [strapiApi, stats, period],
  props: {period: Object},
  data: () => ({
    sales: [],
    shipped: [],
    products: [],
    owed: [],
    chartOptions: {
      responsive: true,
      scales: {
      xAxis: {
        // The axis for this scale is determined from the first letter of the id as `'x'`
        // It is recommended to specify `position` and / or `axis` explicitly.
        type: 'time',
      },
      yAxis: {
        min: 0
      }
    }
    }
  }),
  watch: {
    period(){
      if (this.period.start != null && this.period.end != null){
        this.updateReport()
      }
    }
  },
  async mounted() {
    try {
      this.updateReport()
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    shippedLines() {
      return this.shipped.flatMap(r => {
        return r.lineItems
      });
    },
    soldValue(){
      return this.sum(this.sales.flatMap(r => {
        return Number.parseFloat(r.shopifyJson.total_line_items_price)
      }))
    },
    shippedValue(){
      return this.sum(this.shippedLines.map(l => {
        return l.quantity * l.price
      }))
    },
    chartData(){
      return {
          labels: this.owed.map(o => new Date(o.date).toDateString()),
          datasets: [{
            data: this.owed.map(o => o.data),
            borderWidth: 1,
            tension: 0
          }]
        }
      }
  },
  methods: {
    async sendQuery(endpoint) {
      const period = this.period //this.getPeriod(this.period)
      const query = qs.stringify({
        _where: [
          { created_at_lte: period.end },
          { created_at_gte: period.start }
        ]
      });
      return await this.strapiGet(`${endpoint}?_limit=-1&${query}`);
    },
    async updateReport(){
      this.shipped = await this.sendQuery('shipments');
      this.sales = await this.sendQuery('orders')
      const owedDates = this.getIntervalDates(this.period)
      this.owed = await Promise.all(
        owedDates.map(async (date) => {
          const data = await this.strapiGet(`orders/owed?date=${date}`)
          return {
            date: date,
            data: data
          }  
        })
      )
    }
  }
};
</script>
<style>
</style>
