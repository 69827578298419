`<template>
  <v-container>
    <v-row class="text-h5">
      <v-col cols="10"> Reports </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select :items="reports" v-model="report" item-text="name" item-value="value" label="Show me"></v-select>
      </v-col>
      <v-col>
        <v-select
          :items="periods"
          v-model="periodName"
          label="Over the"
        ></v-select>
      </v-col>
      <v-col v-if="periodName == 'Custom'">
        <v-date-picker range v-model="customPeriod"/>
      </v-col>
    </v-row>
    <component :is="report" :period='period'/>
    <!-- <inentory-use :period='period'/> -->
  </v-container>
</template>

<script>

//mixins
import strapiApi from "../mixins/strapiApi";
import stats from "../mixins/stats"
import period from "../mixins/period"

//reports
import InventoryUse from '../reports/InventoryUse.vue';
import SoldShipped from '../reports/SoldShipped.vue'

export default {
  components: { InventoryUse, SoldShipped },
  name: "ReportsView",
  mixins: [strapiApi, stats, period],
  data: () => ({
    reports: [{name:"Inventory Use", value: "inventory-use"},
    {name: "Sold and Shipped", value: "sold-shipped"}],
    report: "inventory-use",
    periodName: "Last Month",
    customPeriod: [],
    result: null,
    products: [],
  }),
  async mounted() {
  },
  computed: {
    period(){
      if (this.periodName == "Custom"){
        return {
            start: this.customPeriod[0],
            end: this.customPeriod[1]
          }
      }
      return this.getPeriod(this.periodName)
    }
  },
  methods: {
  }
};
</script>
<style>
</style>
