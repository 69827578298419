<template>
  <div>
    <canvas id="myChart"></canvas>
  </div>
</template>


<script>
import Chart from "chart.js"

export default {
  props: { data: Object, config: Object },
  mounted() {
    this.updateChart()
  },
  watch:{
    data(){
      this.updateChart()
    },
    config(){
      this.updateChart()
    }
  },
  methods: {
    updateChart() {
      const ctx = document.getElementById("myChart")
      new Chart(ctx, {
        type: "line",
        data: this.data,
        options: this.options
      })
    },
  },
}
</script>

<style>
</style>